export default function (data, fields, filename = 'report') {
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    fields.map((field) => field.label).join(',') +
    '\n' +
    data
      .map((dataItem) => {
        return fields.map((field) => {
          let value = dataItem[field.key];
          if (typeof value === 'number') {
            value = '"' + String(value).replace('.', ',') + '"';
          }
          return value;
        });
      })
      .join('\n');
  const encodedUri = encodeURI(csvContent);

  const hiddenElement = document.createElement('a');
  hiddenElement.href = encodedUri;
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
}
